import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import silverIcon from '@/asset/home/sets/silver.png';
import goldIcon from '@/asset/home/sets/gold.png';
import platinumIcon from '@/asset/home/sets/platinum.png';
import diamondIcon from '@/asset/home/sets/diamond.png';
import copy from 'copy-to-clipboard';
import { Button, Badge } from "antd";
import { useStore } from "@/hooks/useStore";
import { STORE_USER } from "@/stores/createStores";
import { UserLevel } from "@/constant/level";
import intl from 'react-intl-universal';
import { getPublicKey, createCheckoutSession } from "@/service/payment";
import { userCenter } from '@/service/user';

import './index.less';

const PlansBlock = () => {
    const user = useStore(STORE_USER);
    const [paymentPeriod, setPaymentPeriod] = useState('yearly');

    // only 4 level left
    const membershipTypeIntMap = {
        'free': 0,
        // 'silver': 1, // retired
        'gold': 2,
        'platinum': 3,

        // "silverAlgo": 4, // retired
        // 'goldAlgo': 5, // retired
        // "algo": 6, // retired
        'diamond': 7, 
    }

    var uiPriceIdMap;
    // Running locally (yarn start)
    if (window.SERVER_DATA === undefined) {
        // local test mode 
        uiPriceIdMap = {
            // silver
            1: {
                'monthly': 'price_1JJKYfKkzmtms9sLDgmHGuD4',
                'yearly': 'price_1JLrtrKkzmtms9sLIDfmNS3h',
            },
            // gold 
            2: {
                'monthly': 'price_1J1ZduKkzmtms9sLihMDDcb5',
                'yearly': 'price_1JPHgbKkzmtms9sLn2K2L5XZ',
            },
            // platinum
            3: {
                'monthly': 'price_1OxVsRKkzmtms9sLM77Oo6WB',
                'yearly': 'price_1OxVsRKkzmtms9sL3xarr3am',
            },
            // 4 silverAlgo
            4: {
                'monthly': 'price_1O0zT1Kkzmtms9sLguqtFxAb',
                'yearly': 'price_1O0zTkKkzmtms9sLEVnxWcEo',
            },
            // 5 goldAlgo
            5: {
                'monthly': 'price_1O0zVHKkzmtms9sLzoapzAiU',
                'yearly': 'price_1O0zVRKkzmtms9sLNhzSCD6u',
            },
            // 6 algo
            6: {
                'monthly': 'price_1OxVu1Kkzmtms9sLGvWVNEa6',
                'yearly': 'price_1OxVu1Kkzmtms9sLGvWVNEa6',
            },
        };
    }
    // Rendered by Jinja2
    else {
        const priceIds = window.SERVER_DATA["STRIPE_PRICE_IDS"]
        uiPriceIdMap = {
            1: {
                'monthly': priceIds["silver_monthly"],
                'yearly': priceIds["silver_yearly"],
            },
            2: {
                'monthly': priceIds["gold_monthly"],
                'yearly': priceIds["gold_yearly"],
            },
            3: {
                'monthly': priceIds["platinum_monthly"],
                'yearly': priceIds["platinum_yearly"],
            },
            // 4 silverAlgo
            4: {                
                'monthly': priceIds["silverqu_monthly"],
                'yearly': priceIds["silverqu_yearly"],
            },
            // 5 goldAlgo
            5: {                
                'monthly': priceIds["goldqu_monthly"],
                'yearly': priceIds["goldqu_yearly"],
            },
            // 6 algo
            6: {
                'monthly': priceIds["qu_monthly"],
                'yearly': priceIds["qu_yearly"],                
            },
        };
    }

    // console.log(">>",window.SERVER_DATA, uiPriceIdMap)

    // 个人中心
    let navigate = useNavigate(); 
    const gotoUserCenter = async () => {
        const res = await userCenter();
        if(res.status === 200){
            // console.log(res)
            window.location.href = res.data.redirect;
        }
    }
    const setup = async (membership) => {
        // 检查用户是否登录 是否可以省略
        await user.userInfo();
        const publishableKeyData = await getPublicKey();
        // eslint-disable-next-line no-undef
        const stripe = Stripe(publishableKeyData.data.publishableKey);
        if (user.level && user.level !== 'Free' && membership > membershipTypeIntMap[user.level.toLowerCase()]) {
            // redirectToCustomerPortal();
        } else {
            const priceId = uiPriceIdMap[membership][paymentPeriod];
            var refrenceId;
            try {
                // eslint-disable-next-line no-undef
                refrenceId = Rewardful.referral;
            } catch (e) {
                refrenceId = "";
            }
            const sessionData = await createCheckoutSession(priceId, refrenceId);
            // Call Stripe.js method to redirect to the new Checkout page
            stripe.redirectToCheckout({ sessionId: sessionData.data.sessionId });

        };
    }

    //renderSubscribe(1) = render silver based on User level and membershipType 
    const renderSubscribe = (membershipType) => {
        if (!user || !user.level) {
            if (membershipType == 0) {
                return <Button className='button' onClick={() => { setup(membershipType) }}> {intl.get('home.sets.subscribeFree')}</Button>
            } else {
                return <Button className='button' onClick={() => { setup(membershipType) }}> {intl.get('home.sets.subscribe')}</Button>
            }
            
        } else {            
            // console.log(">>", user.level, membershipType)
            if (user.level === UserLevel.free) {
                // free 0, rest all upgrade                
                if (membershipType == 0) {
                    return <div className='button-text'>{intl.get('home.sets.subscribed')}</div>
                } else if (membershipType >= 1) {
                    // user silver level display gold 2 - algo 6 = upgrade
                    return <Button className='button' onClick={() => { setup(membershipType) }}> {intl.get('home.sets.upgrade')}</Button>
                }
            } else if (user.level === UserLevel.gold) {
                // user gold level display silver 1 = change 
                if (membershipType == 1 || membershipType == 6) {
                    return <Button className='button' onClick={gotoUserCenter}> {intl.get('home.sets.downgrade')}</Button>
                } else if (membershipType == 2) {
                    // user gold level display gold 2 = subscribed 
                    return <div className='button-text'>{intl.get('home.sets.subscribed')}</div>
                } else if (membershipType == 3) {
                    // user gold level display silverAlgo 4 - algo 6= upgrade     
                    return <Button className='button' onClick={gotoUserCenter}> {intl.get('home.sets.upgrade')}</Button>
                }
            } else if (user.level === UserLevel.platinum) {
                // platinum = 3
                if (membershipType < 3) { // downgrade
                    return <Button className='button' onClick={gotoUserCenter}> {intl.get('home.sets.downgrade')}</Button>                    
                } else if (membershipType == 3) {
                    // platinum = sub
                    return <div className='button-text'>{intl.get('home.sets.subscribed')}</div>
                }
            // } else if (user.level === UserLevel.silverAlgo) {
                // user=silverAlgo level display silver 1/ gold 2 = downgrade
                // if (membershipType == 1 || membershipType == 2 || membershipType == 6) {
                //     return <Button className='button' onClick={gotoUserCenter}> {intl.get('home.sets.downgrade')}</Button>
                    
                // } else if (membershipType == 4) {
                // // user=silverAlgo level display sliverAlgo 4 = subscribed
                //     return <div className='button-text'>{intl.get('home.sets.subscribed')}</div>
                // } else if (membershipType == 3  || membershipType == 5) {
                // // user=silverAlgo level display goldAlgo 5 = upgrade 
                //     return <Button className='button' onClick={gotoUserCenter}> {intl.get('home.sets.upgrade')}</Button>
                // }
            // } else if (user.level === UserLevel.goldAlgo) {
                // // user=goldAlgo level display silver 1/ gold 2/ silver algo 4/ alog 6 = downgrade
                // if (membershipType == 1 || membershipType == 2 || membershipType ==4 || membershipType == 6) {
                //     return <Button className='button' onClick={gotoUserCenter}> {intl.get('home.sets.downgrade')}</Button>                    
                // } else if (membershipType == 3 || membershipType == 5) {
                // // user=goldAlgo level display goldAlgo 5 = subscribed
                //     return <div className='button-text'>{intl.get('home.sets.subscribed')}</div>
                // }             
            } else if (user.level === UserLevel.algo) {
                // user=Algo level display silver 1
                if (membershipType == 1) {
                    return <Button className='button' onClick={gotoUserCenter}> {intl.get('home.sets.downgrade')}</Button>
                } else if (membershipType == 6) {
                // user=silverAlgo level display sliverAlgo 4 = subscribed
                    return <div className='button-text'>{intl.get('home.sets.subscribed')}</div>
                } else {
                    return <Button className='button' onClick={gotoUserCenter}> {intl.get('home.sets.upgrade')}</Button>
                }
                                    
            } 

        }

    }

    return (
        <div className='sets-block-content'>
            <div className='payment-mode'>
                <Badge.Ribbon className="badge-payment" placement='end' text='买十送二' color='red'></Badge.Ribbon>
                <span
                    className={paymentPeriod === 'monthly' ? 'active sub-month' : 'sub-month'}
                    onClick={() => setPaymentPeriod('monthly')}
                >
                    {intl.get('home.sets.monthly')}
                </span>
                <span
                    className={paymentPeriod === 'yearly' ? 'active sub-year' : 'sub-year'}
                    onClick={() => setPaymentPeriod('yearly')}
                >
                    {intl.get('home.sets.annual')}
                </span>                
            </div>

            <div className='container'>
                {/* 1 level = Free =0   */}
                <div className={`free set ${user && user.level && (user.level === UserLevel.free) ? 'disable' : ''}`}>
                    <Badge.Ribbon className="badge-baselevel" placement='start' text="New!" color='red'></Badge.Ribbon>
                    <div className='title'>
                        <img src={silverIcon} alt=''></img>
                        <span>Base Level</span>
                    </div>
                    <div className='price'>
                        <span>$</span>
                        <span>
                            {paymentPeriod === 'yearly' ? 0 : 0}
                        </span>
                        <span>/{paymentPeriod === 'yearly' ? intl.get('home.sets.month') : intl.get('home.sets.month')}</span>
                    </div>
                    {/* {paymentPeriod === 'yearly' ? 
                        <div className='total-price'>                        
                            <span>{intl.get('home.sets.save')}</span>                                            
                            <span>$98</span>                        
                            <span></span>                        
                        </div>
                     :
                        <div className='total-price'>                        
                            <span>{intl.get('home.sets.annual')}</span>
                            <span>$490</span>
                            <span>$588</span>
                        </div>
                    }                   */}
                    <Badge.Ribbon className="badge-unusalflow" placement='start' text='New!' color='red'></Badge.Ribbon>
                    {intl.getHTML('home.sets.free')}
                    {renderSubscribe(0)}
                </div>

                {/* 2 level = Gold =2   */}
                <div className={`gold set ${user && user.level && user.level === UserLevel.gold ? 'disable' : ''}`}>
                    <div className='title'>
                        <img src={goldIcon} alt=''></img>
                        <span>Gold Level</span>
                    </div>
                    <div className='price'>
                        <span>$</span>
                        <span>{paymentPeriod === 'yearly' ? 82.5 : 99}</span>                        
                        <span>/{paymentPeriod === 'yearly' ? intl.get('home.sets.month') : intl.get('home.sets.month')}</span>
                    </div>
                    {paymentPeriod === 'yearly' ? 
                        <div className='total-price'> 
                            {intl.get('home.sets.annual')} $990/{intl.get('home.sets.year')}. 
                            <span>{intl.get('home.sets.save')}</span>                     
                            <span>$198</span>                        
                            <span></span>                        
                        </div>
                     :
                        <div className='total-price'>                        
                        </div>
                    }                 

                    <Badge.Ribbon className="badge-optiondata" placement='start' text='New!' color='red'></Badge.Ribbon>

                    {intl.getHTML('home.sets.gold')}
                    {renderSubscribe(2)}
                    
                    {/* {!user || !user.level || user.level === UserLevel.free ? <Button className='button' onClick={() => { setup(2) }}> {intl.get('home.sets.subscribe')}</Button> : null}
                    {user && user.level && user.level === UserLevel.silver ? <Button className='button' onClick={gotoUserCenter}> {intl.get('home.sets.upgrade')}</Button> : null}
                    {user && user.level && user.level === UserLevel.gold ? <div className='button-text'>{intl.get('home.sets.subscribed')}</div> : null} */}
                </div>

                {/* 3  level =platinum  = 3      */}
                <div className='platinum set '>                                        
                    <div className='title'>
                        <img src={platinumIcon} alt=''></img>
                        <span>Platinum Level</span>
                    </div>
                    <div className='price'>
                        <span>$</span>
                        <span>
                            {paymentPeriod === 'yearly' ? 124.5 : 149}
                        </span>
                        <span>/{paymentPeriod === 'yearly' ? intl.get('home.sets.month') : intl.get('home.sets.month')}</span>
                    </div>
                    {paymentPeriod === 'yearly' ? 
                        <div className='total-price'> 
                            {intl.get('home.sets.annual')} $1490/{intl.get('home.sets.year')}.                         
                            <span>{intl.get('home.sets.save')}</span>                     
                            <span>$298</span>                        
                            <span></span>                        
                        </div>
                     :
                        <div className='total-price'>                        
                        </div>
                    }                 

                    <Badge.Ribbon className="badge-qualgo" placement='start' text='New!' color='red'></Badge.Ribbon>
                    {intl.getHTML('home.sets.platinum')}
                    {renderSubscribe(3)}
                </div>

                {/* 4  level = diamond  = 7      */}
                <div className='diamond set '>
                    <div className='title'>
                        <img src={diamondIcon} alt=''></img>
                        <span>Diamond Level</span>
                    </div>
                    <div>
                        {intl.getHTML('home.sets.diamond')}
                        <Button className='button' onClick={() => copy('business@quantunicorn.com')}>{intl.get('home.sets.copy')}</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PlansBlock;
