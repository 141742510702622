import React from "react";
import intl from 'react-intl-universal';
import { RightCircleOutlined } from '@ant-design/icons'
import './index.less';

import silverIcon from '@/asset/home/sets/silver.png';
import goldIcon from '@/asset/home/sets/gold.png';
import platinumIcon from '@/asset/home/sets/platinum.png';

const PlanHighlightBlock = () => {
    const plan = [
        {
            title: "Base Level",
            imgSrc: silverIcon,
            color: 'rgba(108, 74, 255, 1)',
            description: intl.getHTML('home.news.article1'),
            tagName: intl.getHTML('home.news.article')
        },
        {
            title: "Gold Level",
            imgSrc: goldIcon,
            color: 'rgba(108, 74, 255, 1)',
            description: intl.getHTML('home.news.article2'),
            tagName: intl.getHTML('home.news.article')
        },
        {
            title: "Platinum Level",
            imgSrc: platinumIcon,
            color: 'rgba(108, 74, 255, 1)',
            description: intl.getHTML('home.news.article3'),
            tagName: intl.getHTML('home.news.article')
        },
    ];

    return (
        <div className='planHighlight-block'>
            <div className='news-content'>
                {
                    plan.map((v, index) => {
                        return (
                            <div className='new-item' key={index}>
                                <div className='description'>
                                    <h1>
                                        <img className="icon" src={v.imgSrc} alt=''></img>{v.title}
                                    </h1>
                                    <div>
                                        {v.description}
                                    </div>
                                </div>                                
                            </div>
                        )
                    })
                }
            </div>
            <div className='view-more'>
                <a href='#plans'>
                    <span>{intl.get('home.news.button')}</span>
                    <RightCircleOutlined />
                </a>
            </div>
        </div>
    )
}

export default PlanHighlightBlock;
